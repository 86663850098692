import {CoreMenu} from '@core/types';
import {SHOPPER_ROLE} from "../../@core/security/auth-roles";


export const menu: CoreMenu[] = [

    {
        id: 'purchases',
        hidden: false,
        type: 'item',
        icon: 'fa-cart-shopping',
        translate: 'side_menu.zenki_pay.purchases.label',
        url: '/purchase/',
        exactMatch: false,
        role: [SHOPPER_ROLE]
    },
    {
        id: 'refunds',
        hidden: false,
        type: 'item',
        icon: 'fa-money-bill-transfer',
        translate: 'side_menu.zenki_pay.refunds.label',
        url: '/refunds/',
        exactMatch: false,
        role: [SHOPPER_ROLE]
    },
    {
        id: 'profile',
        hidden: false,
        type: 'item',
        icon: 'fa-clipboard-user',
        translate: 'side_menu.zenki_pay.profile.label',
        url: '/profile',
        exactMatch: true,
        role: [SHOPPER_ROLE]
    }

];
