import {Pipe, PipeTransform} from '@angular/core';
import {CoreMenuItem} from "../../types";
import {User} from "../../../app/auth/models";


@Pipe({
  name: 'showElementIfHasRole',
  pure: false
})
export class ShowElementIfHasRolePipe implements PipeTransform {
  transform(item: CoreMenuItem, user: User): boolean {
    if (item.role) {
      return this._showElementByRole(item, user);
    } else {
      return false;
    }
  }

  private _showElementByRole(itemMenu, user): boolean {
    return user?.roles.some(role => itemMenu?.role.includes(role));
  }

}
