import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import {SkeletonDirective} from "./skeleton/skeleton.directive";
import {RectComponent} from "./skeleton/rect-ske.component";


@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective, SkeletonDirective, RectComponent],
  imports: [],
  exports: [RippleEffectDirective, FeatherIconDirective, SkeletonDirective]
})
export class CoreDirectivesModule {}
