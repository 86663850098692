import {Pipe, PipeTransform} from '@angular/core';

import {Router} from "@angular/router";
import {CoreMenuItem} from "../../types";

@Pipe({
  name: 'routerLinkActiveOptions',
  pure: false
})
export class RouterLinkActiveOptionsPipe implements PipeTransform {

  constructor(private readonly _router: Router) {
  }

  transform(item: CoreMenuItem): { exact } {
    return {
      exact: this._getRouterLinkActiveOptions(item)
    };
  }

  private _getRouterLinkActiveOptions(item: CoreMenuItem): boolean {

    const urlSegments = this._router.url.split('?');
    const url = urlSegments[0];
    return item.exactMatch && item.activeUrls ? !item.activeUrls.includes(url)
      : item.exactMatch ? item.exactMatch : false;
  }

}
