import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {FakeDbService} from '@fake-db/fake-db.service';

import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {ContextMenuModule} from '@ctrl/ngx-rightclick';

import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {CardSnippetModule} from '@core/components/card-snippet/card-snippet.module';

import {coreConfig} from 'app/app-config';
import {AuthGuard, ErrorInterceptor, fakeBackendProvider, JwtInterceptor} from 'app/auth/helpers'; // used to create fake backend
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';

import {ContextMenuComponent} from 'app/main/extensions/context-menu/context-menu.component';
import {
    AnimatedCustomContextMenuComponent
} from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import {
    BasicCustomContextMenuComponent
} from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import {
    SubMenuCustomContextMenuComponent
} from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";
import {HighchartsChartModule} from "highcharts-angular";
import {AuthenticationService} from "./auth/service";
import {NoAuthGuard} from "./auth/helpers/no-auth.guards";
import localeEs419 from '@angular/common/locales/es-419';
import localeEnUS from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';

import { ServiceWorkerModule } from '@angular/service-worker';
import {CacheBusterInitializerProvider} from "../@core/utils/providers/cache-buster.initializer.provider";
import {NgxSpinnerModule} from "ngx-spinner";
import { WindowProvider } from '@core/providers/window';
import {LanguageInterceptor} from "./auth/helpers/language.interceptor.service";
import { AuthCheckoutInterceptorProvider } from './pay/provider/auth-interceptor.provider';
import {CacheInterceptor} from "./auth/helpers/cache.interceptor";
import { DataDogInitializerProvider } from '@core/providers/data-dog';
import {RedirectToLoginResolver} from "./pay/resolver/redirect-to-login-resolver.resolver";
import {EmptyComponentComponent} from "./main/pages/miscellaneous/empty-component/empty-component.component";
import {JavaLocaleEnum, LanguagesEnum} from "../@core/enums";
import { AppRoutingModule } from './app-routing.module';

registerLocaleData(localeEs419, JavaLocaleEnum.LATAM);
registerLocaleData(localeEnUS, JavaLocaleEnum.EUA);
registerLocaleData(localeEs, LanguagesEnum.ES);

export class DynamicLocaleId extends String {
    constructor(protected _translateService: TranslateService) {
        super('');
    }

    toString() {
        return this._translateService.currentLang;
    }
}



@NgModule({
    declarations: [
        AppComponent,
        ContextMenuComponent,
        BasicCustomContextMenuComponent,
        AnimatedCustomContextMenuComponent,
        SubMenuCustomContextMenuComponent,
        EmptyComponentComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        HttpClientModule,
        HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        AppRoutingModule,
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
                deps: [HttpClient]
            }
        }),
        ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
        HighchartsChartModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true,
          registrationStrategy: 'registerImmediately'
        })
    ],
    providers: [
        AuthenticationService,
        CacheBusterInitializerProvider,
        {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService]},
        // ! IMPORTANT: Provider used to create fake backend, comment while using real API
        fakeBackendProvider,
        WindowProvider,
        AuthCheckoutInterceptorProvider,
        DataDogInitializerProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
