export const PAGE_GAP = 1;
export const PAGE_DEFAULT = 0;
export const DEFAULT_PAGE_SIZE = 15;

export function numberItemsStartShowing(pageSize: number, pageNumber: number): number {
  return (pageSize * pageNumber) + PAGE_GAP;
}

export function numberItemsEndShowing(pageSize: number, pageNumber: number, numberOfElements: number): number {
  return (pageSize * pageNumber) + numberOfElements;
}

export function showPagination(pageSize: number, totalElements: number): boolean {
  return ((totalElements / pageSize) > PAGE_GAP)
}

export function pageToPagination(pageNumber: number): number {
  return (pageNumber + PAGE_GAP);
}

export function collectionSize(totalElements: number): number {
  return (totalElements);
}

export function equalAccessKey(accessKeyFromElement: string, accessKey4Validate: string): boolean {
  return (accessKeyFromElement === accessKey4Validate);
}
