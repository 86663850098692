import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import {LocalCurrencyFiatPipe} from "./local-currency-fiat.pipe";
import {LocalDatePipe} from "./local-date.pipe";
import { UsernamePipe } from './username.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, LocalCurrencyFiatPipe, LocalDatePipe, UsernamePipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe, LocalCurrencyFiatPipe, LocalDatePipe, UsernamePipe]
})
export class CorePipesModule {}
