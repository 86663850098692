import { Component, Input } from '@angular/core';
import {CoreMenuItem} from "../../../../types";

@Component({
  selector: '[core-menu-horizontal-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuHorizontalItemComponent {
  @Input() public item: CoreMenuItem;
}
