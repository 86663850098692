import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(email: string): any {
    return email
      ?.split(' ')
      .map(n => n[0])
      .join('')+'@';
  }
}
