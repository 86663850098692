export class ChatFakeData {
  public static profileUser = {
    id: 11,
    avatar: 'assets/images/portrait/small/avatar-s-11.jpg',
    fullName: 'John Doe',
    role: 'admin',
    about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false
    }
  };
  public static contacts = [
    {
      id: 1,
      fullName: 'Felecia Rower',
      role: 'Frontend Developer',
      about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
      avatar: 'assets/images/avatars/1.png',
      status: 'offline'
    },
    {
      id: 2,
      fullName: 'Adalberto Granzin',
      role: 'UI/UX Designer',
      about:
        'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
      avatar: 'assets/images/avatars/2.png',
      status: 'busy'
    },
    {
      id: 3,
      fullName: 'Joaquina Weisenborn',
      role: 'Town planner',
      about:
        'Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.',
      avatar: 'assets/images/avatars/3.png',
      status: 'busy'
    },
    {
      id: 4,
      fullName: 'Verla Morgano',
      role: 'Data scientist',
      about:
        'Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.',
      avatar: 'assets/images/avatars/4.png',
      status: 'online'
    },
    {
      id: 5,
      fullName: 'Margot Henschke',
      role: 'Dietitian',
      about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
      avatar: 'assets/images/avatars/5.png',
      status: 'busy'
    },
    {
      id: 6,
      fullName: 'Sal Piggee',
      role: 'Marketing executive',
      about:
        'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
      avatar: 'assets/images/avatars/6.png',
      status: 'online'
    },
    {
      id: 7,
      fullName: 'Miguel Guelff',
      role: 'Special educational needs teacher',
      about:
        'Biscuit powder oat cake donut brownie ice cream I love soufflé. I love tootsie roll I love powder tootsie roll.',
      avatar: 'assets/images/avatars/7.png',
      status: 'online'
    },
    {
      id: 8,
      fullName: 'Mauro Elenbaas',
      role: 'Advertising copywriter',
      about:
        'Bear claw ice cream lollipop gingerbread carrot cake. Brownie gummi bears chocolate muffin croissant jelly I love marzipan wafer.',
      avatar: 'assets/images/avatars/12.png',
      status: 'away'
    },
    {
      id: 9,
      fullName: 'Bridgett Omohundro',
      role: 'Designer, television/film set',
      about:
        'Gummies gummi bears I love candy icing apple pie I love marzipan bear claw. I love tart biscuit I love candy canes pudding chupa chups liquorice croissant.',
      avatar: 'assets/images/avatars/9.png',
      status: 'offline'
    },
    {
      id: 10,
      fullName: 'Zenia Jacobs',
      role: 'Building surveyor',
      about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
      avatar: 'assets/images/avatars/10.png',
      status: 'away'
    }
  ];

  public static chats = [
    {
      id: 1,
      userId: 2,
      unseenMsgs: 1,
      chat: [
        {
          message: 'Hi',
          time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Hello. How can I help You?',
          time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
          senderId: 2
        },
        {
          message: 'Can I get details of my last transaction I made last month?',
          time: 'Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'We need to check if we can provide you such information.',
          time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
          senderId: 2
        },
        {
          message: 'I will inform you as I get update on this.',
          time: 'Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)',
          senderId: 2
        },
        {
          message: 'If it takes long you can mail me at my mail address.',
          time: 'dayBeforePreviousDay',
          senderId: 11
        }
      ]
    },
    {
      id: 2,
      userId: 1,
      unseenMsgs: 0,
      chat: [
        {
          message: "How can we help? We're here for you!",
          time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Hey John, I am looking for the best admin template. Could you please help me to find it out?',
          time: 'Mon Dec 10 2018 07:45:23 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: 'It should be Bootstrap 4 compatible.',
          time: 'Mon Dec 10 2018 07:45:55 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: 'Absolutely!',
          time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Modern admin is the responsive bootstrap 4 admin template.!',
          time: 'Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'Looks clean and fresh UI.',
          time: 'Mon Dec 10 2018 07:46:23 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: "It's perfect for my next project.",
          time: 'Mon Dec 10 2018 07:46:33 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: 'How can I purchase it?',
          time: 'Mon Dec 10 2018 07:46:43 GMT+0000 (GMT)',
          senderId: 1
        },
        {
          message: 'Thanks, from ThemeForest.',
          time: 'Mon Dec 10 2018 07:46:53 GMT+0000 (GMT)',
          senderId: 11
        },
        {
          message: 'I will purchase it for sure. 👍',
          time: 'previousDay',
          senderId: 1
        }
      ]
    }
  ];


  public static mapChart =
      [
        ["fo", 0],
        ["um", 1],
        ["us", 2],
        ["jp", 3],
        ["sc", 4],
        ["in", 5],
        ["fr", 6],
        ["fm", 7],
        ["cn", 8],
        ["pt", 9],
        ["sw", 10],
        ["sh", 11],
        ["br", 12],
        ["ki", 13],
        ["ph", 14],
        ["mx", 15],
        ["es", 16],
        ["bu", 17],
        ["mv", 18],
        ["sp", 19],
        ["gb", 20],
        ["gr", 21],
        ["as", 22],
        ["dk", 23],
        ["gl", 24],
        ["gu", 25],
        ["mp", 26],
        ["pr", 27],
        ["vi", 28],
        ["ca", 29],
        ["st", 30],
        ["cv", 31],
        ["dm", 32],
        ["nl", 33],
        ["jm", 34],
        ["ws", 35],
        ["om", 36],
        ["vc", 37],
        ["tr", 38],
        ["bd", 39],
        ["lc", 40],
        ["nr", 41],
        ["no", 42],
        ["kn", 43],
        ["bh", 44],
        ["to", 45],
        ["fi", 46],
        ["id", 47],
        ["mu", 48],
        ["se", 49],
        ["tt", 50],
        ["my", 51],
        ["pa", 52],
        ["pw", 53],
        ["tv", 54],
        ["mh", 55],
        ["cl", 56],
        ["th", 57],
        ["gd", 58],
        ["ee", 59],
        ["ag", 60],
        ["tw", 61],
        ["bb", 62],
        ["it", 63],
        ["mt", 64],
        ["vu", 65],
        ["sg", 66],
        ["cy", 67],
        ["lk", 68],
        ["km", 69],
        ["fj", 70],
        ["ru", 71],
        ["va", 72],
        ["sm", 73],
        ["kz", 74],
        ["az", 75],
        ["tj", 76],
        ["ls", 77],
        ["uz", 78],
        ["ma", 79],
        ["co", 80],
        ["tl", 81],
        ["tz", 82],
        ["ar", 83],
        ["sa", 84],
        ["pk", 85],
        ["ye", 86],
        ["ae", 87],
        ["ke", 88],
        ["pe", 89],
        ["do", 90],
        ["ht", 91],
        ["pg", 92],
        ["ao", 93],
        ["kh", 94],
        ["vn", 95],
        ["mz", 96],
        ["cr", 97],
        ["bj", 98],
        ["ng", 99],
        ["ir", 100],
        ["sv", 101],
        ["sl", 102],
        ["gw", 103],
        ["hr", 104],
        ["bz", 105],
        ["za", 106],
        ["cf", 107],
        ["sd", 108],
        ["cd", 109],
        ["kw", 110],
        ["de", 111],
        ["be", 112],
        ["ie", 113],
        ["kp", 114],
        ["kr", 115],
        ["gy", 116],
        ["hn", 117],
        ["mm", 118],
        ["ga", 119],
        ["gq", 120],
        ["ni", 121],
        ["lv", 122],
        ["ug", 123],
        ["mw", 124],
        ["am", 125],
        ["sx", 126],
        ["tm", 127],
        ["zm", 128],
        ["nc", 129],
        ["mr", 130],
        ["dz", 131],
        ["lt", 132],
        ["et", 133],
        ["er", 134],
        ["gh", 135],
        ["si", 136],
        ["gt", 137],
        ["ba", 138],
        ["jo", 139],
        ["sy", 140],
        ["mc", 141],
        ["al", 142],
        ["uy", 143],
        ["cnm", 144],
        ["mn", 145],
        ["rw", 146],
        ["so", 147],
        ["bo", 148],
        ["cm", 149],
        ["cg", 150],
        ["eh", 151],
        ["rs", 152],
        ["me", 153],
        ["tg", 154],
        ["la", 155],
        ["af", 156],
        ["ua", 157],
        ["sk", 158],
        ["jk", 159],
        ["bg", 160],
        ["qa", 161],
        ["li", 162],
        ["at", 163],
        ["sz", 164],
        ["hu", 165],
        ["ro", 166],
        ["ne", 167],
        ["lu", 168],
        ["ad", 169],
        ["ci", 170],
        ["lr", 171],
        ["bn", 172],
        ["iq", 173],
        ["ge", 174],
        ["gm", 175],
        ["ch", 176],
        ["td", 177],
        ["kv", 178],
        ["lb", 179],
        ["dj", 180],
        ["bi", 181],
        ["sr", 182],
        ["il", 183],
        ["ml", 184],
        ["sn", 185],
        ["gn", 186],
        ["zw", 187],
        ["pl", 188],
        ["mk", 189],
        ["py", 190],
        ["by", 191],
        ["cz", 192],
        ["bf", 193],
        ["na", 194],
        ["ly", 195],
        ["tn", 196],
        ["bt", 197],
        ["md", 198],
        ["ss", 199],
        ["bw", 200],
        ["bs", 201],
        ["nz", 202],
        ["cu", 203],
        ["ec", 204],
        ["au", 205],
        ["ve", 206],
        ["sb", 207],
        ["mg", 208],
        ["is", 209],
        ["eg", 210],
        ["kg", 211],
        ["np", 212]
]
  ;
}
