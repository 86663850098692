import { Component, OnInit, ChangeDetectionStrategy, Output, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from '@core/decorators';
import { ProfileType } from '@core/enums/profile-type';
import { blobToBase64 } from '@core/utils/media.core';
import { User } from 'app/auth/models';
import { Person } from 'app/auth/models/person';
import { UserService } from 'app/auth/service';
import { ImgService } from 'app/auth/service/img.service';
import { StorageSessionDataService } from 'app/auth/service/storage-session-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe
export class NavbarMenuComponent implements OnInit {
  @Output() public logoutEvent = new EventEmitter<any>();
  @Output() public closeEvent = new EventEmitter<any>();
  @Input() public profileRoute = '/profile';

  public avatarImageExist = false;
  public srcUrl: any;
  public merchantUnits: any;
  public person: Person;
  private subscriptions: Subscription[] = [];
  public currentUser: User = {
    id: '',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    avatar: '',
    role: null,
    fullName: '',
  };

  constructor(
    private _sessionStorage: StorageSessionDataService,
    private readonly _imgService: ImgService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly domSanitizer: DomSanitizer,
    private readonly _userService: UserService
  ) {}

  ngOnInit(): void {
    this.getAvatar();
    this.getCurrentUser();

    this.subscriptions.push(
      this._imgService.$avatarUpdated.subscribe((updated) => {
        if (updated) {
          this.getAvatar();
        }
      })
    );

    this.subscriptions.push(
      this._userService.$personUpdated.subscribe((updated) => {
        if (updated) {
          this.getCurrentUser();
        }
      })
    );
  }

  private getAvatar() {
    this.subscriptions.push(
      this._imgService.getAvatarImage().subscribe((res: Blob) => {
        blobToBase64(res).then((res: string) => {
          this.srcUrl = this.domSanitizer.bypassSecurityTrustUrl(res);
          this.avatarImageExist = true;
          this._cdr.detectChanges();
        });
      })
    );
  }

  private getCurrentUser() {
    if (this._sessionStorage && this._sessionStorage.user) {
      this.currentUser = {
        ...this._sessionStorage.user,
        ...this._sessionStorage.person,
      };
      this.currentUser.fullName = `${this.currentUser.firstName} ${this.currentUser.lastName}`;
      this._cdr.detectChanges();
    }
  }

  logout() {
    this.logoutEvent.emit(true);
  }
}
