/**
 * function that convert a blob objet into base64 objet
 */
export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function getMediaType(fileName: string) {
  let contentType;
  switch (fileName.split('.').pop()) {
    case 'svg':
      contentType = 'image/svg+xml'
      break;
    case 'jpeg':
      contentType = 'image/jpeg'
      break;
    case 'jpg':
      contentType = 'image/jpg'
      break;
    case 'png':
      contentType = 'image/png'
      break;
    case 'gif':
      contentType = 'image/gif'
      break;
  }
  return contentType;
}

/**
 * function that validate size file
 */
export function validateSizeFile(file: Blob, maxFileSize) {

  let successSize = false;
  return new Promise((resolve, _) => {

    console.log('file.size < maxFileSize', file.size, maxFileSize);
    if (file.size < maxFileSize) {
      successSize = true;
    }

    resolve(successSize)

  })

}

export function getExtensionFromBase64Image(base64: string) {
  let extension;
  switch (base64.charAt(0)) {
    case '/':
      extension = 'jpg'
      break;
    case 'i' :
      extension = 'png'
      break;
    case 'R':
      extension = 'gif'
      break;
    case 'U':
      extension = 'webp'
      break;
    case 'P':
      extension = 'svg+xml'
      break;
  }
  return extension;
}
