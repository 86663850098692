<ng-container *ngIf="!item.hidden">
    <!-- item.url -->
    <a class="dropdown-item d-flex align-items-center"
       [ngClass]="item.classes"
       *ngIf="item.url && !item.externalUrl"
       [routerLink]="[item.url]"
       [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="dropdown-item d-flex align-items-center"
       [ngClass]="item.classes"
       *ngIf="item.url && item.externalUrl"
       [href]="item.url"
       [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <i [class]="'fa ' + item.icon" *ngIf="item.icon" style="height: 15px"></i>
        <span>{{ item.translate | translate }}</span>
    </ng-template>
</ng-container>
