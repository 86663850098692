<div class="navbar-menu">
  <div>
    <span class="avatar" *ngIf="this.avatarImageExist">
      <img class="round"
           [src]="this.srcUrl"
           alt="avatar"
           height="50"
           width="50"/>
      <span class="avatar-status-online"></span>
    </span>
    <div *ngIf="!this.avatarImageExist">
      <div class="avatar bg-light-primary">
        <div class="avatar-content">{{ this.currentUser.email | initials }}</div>
        <div class="avatar-status-online"></div>
      </div>
    </div>
  </div>

  <div class="pt-1 user-name break-word">
    <b>{{ this.currentUser.fullName | username }} </b>
    <div class="edit-circle" [routerLink]="this.profileRoute">
      <i class="fas fa-pen"></i>
    </div>
  </div>
  <div class="user-role text-uppercase">
    {{ 'role.'+this.currentUser.role | translate }}
  </div>
  <div class="user-email break-word">
    {{ this.currentUser.email }}
  </div>

  <hr width=130 class="section-line">

  <div>
    <a ngbDropdownItem class="logout" (click)="logout()">
      <span [class]="'mr-50'"></span> {{ 'common.logout' | translate}}
    </a>
  </div>
</div>

