import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot,} from "@angular/router";
import {PaymentLink} from "../interfaces/payment-link";
import {CheckoutService} from "../service/checkout.service";
import {AccessToken} from "../../../@core/models/checkout-access-token";
import {PaymentLinkStatusEnum} from "../../../@core/enums";
import {getFailedPaymentLinkStatus} from "../../../@core/utils/functions";

@Injectable({
  providedIn: "root",
})
export class PaymentLinkResolver implements Resolve<PaymentLink | boolean> {
  constructor(private readonly _checkoutService: CheckoutService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any | boolean> {
    const paymentLinkId = route.paramMap.get("paymentLinkId");
    const paymentLinkSignature = route.queryParams["paymentLinkSignature"];

    return this._checkoutService.getAccessToken( paymentLinkId,
        paymentLinkSignature)
        .toPromise()
        .then((res: AccessToken) => {
          this._checkoutService._setAuthorization(res);
          this._checkoutService.paymentLinkStatus = PaymentLinkStatusEnum.ACTIVE;
        })
        .catch((error)=> {
            this._checkoutService.paymentLinkStatus = getFailedPaymentLinkStatus(error?.status);
           console.error('Error', error)
        })
  }



}
