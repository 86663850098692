import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { isRunningInLocalhost } from '@core/utils/helpers.core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'environments/environment';

function initializeDataDog(document: Document): () => void {
  if (isRunningInLocalhost(document)) {
    return function (): void {};
  }

  return function (): void {
    datadogRum.init({
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      site: 'datadoghq.com',
      service: `portal-${environment.modalEnvironment}-shopper`,
      env: environment.modalEnvironment,
      version: environment.shopperPortalVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  };
}

export const DataDogInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeDataDog,
  deps: [DOCUMENT],
  multi: true,
};


