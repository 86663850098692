import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';
import { environment } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {ToastrService} _toast
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router,
              private _toast: ToastrService,
              private _authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (request.params.get("notDisplayModalError")) {
          console.warn(`[INFO] Next error isn't handled by %s: %o`, this.constructor.name, err);
          return throwError(err);
        }
        if (request.url.startsWith(environment.keycloak.url) && [400, 401, 403].indexOf(err.status) !== -1) {
          this._router.navigate(['/auth/login']);
          this._authenticationService.logout()
          return throwError(err)
        } else if (request.url.startsWith(environment.apiUrl) && [401, 403].indexOf(err.status) !== -1) {
          this._toast.warning(err?.error?.humanMessage, undefined, {
            progressBar: true,
            toastClass: 'ngx-toastr toast'
          });
          this._router.navigate(['/auth/login'])
          this._authenticationService.logout()
          return throwError(err)
        }else if (err?.status >= 400 && err?.status <= 499){
            this._toast.warning(err?.error?.humanMessage, undefined, {
                progressBar: true,
                toastClass: 'ngx-toastr toast'
            });
            return throwError(err)
        }else {

          this._toast.error(err?.error?.humanMessage, 'Something is wrong', {
            toastClass: 'ngx-toastr toast',
            disableTimeOut: false,
            progressBar: true
          });
            return throwError(err)
        }
        // throwError
        const error = err?.error?.message || err?.statusText;
        return throwError(error);
      })
    );
  }
}
