

// import 'chartjs-adapter-luxon';
import {valueOrDefault} from "./helpers.core";
import {environment} from "../environments/environment";
import {FlagEnum, JavaLocaleEnum, LanguagesEnum, LokaliseFileEnum} from "../@core/enums";


// Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
var _seed = Date.now();

export function srand(seed) {
  _seed = seed;
}

export function rand(min, max) {
  min = valueOrDefault(min, 0);
  max = valueOrDefault(max, 0);
  _seed = (_seed * 9301 + 49297) % 233280;
  return min + (_seed / 233280) * (max - min);
}

export function numbers(config) {
  var cfg = config || {};
  var min = valueOrDefault(cfg.min, 0);
  var max = valueOrDefault(cfg.max, 100);
  var from = valueOrDefault(cfg.from, []);
  var count = valueOrDefault(cfg.count, 8);
  var decimals = valueOrDefault(cfg.decimals, 8);
  var continuity = valueOrDefault(cfg.continuity, 1);
  var dfactor = Math.pow(10, decimals) || 0;
  var data = [];
  var i, value;

  for (i = 0; i < count; ++i) {
    value = (from[i] || 0) + this.rand(min, max);
    if (this.rand() <= continuity) {
      data.push(Math.round(dfactor * value) / dfactor);
    } else {
      data.push(null);
    }
  }

  return data;
}

export function points(config) {
  const xs = this.numbers(config);
  const ys = this.numbers(config);
  return xs.map((x, i) => ({x, y: ys[i]}));
}

export function bubbles(config) {
  return this.points(config).map(pt => {
    pt.r = this.rand(config.rmin, config.rmax);
    return pt;
  });
}

export function labels(config) {
  var cfg = config || {};
  var min = cfg.min || 0;
  var max = cfg.max || 100;
  var count = cfg.count || 8;
  var step = (max - min) / count;
  var decimals = cfg.decimals || 8;
  var dfactor = Math.pow(10, decimals) || 0;
  var prefix = cfg.prefix || '';
  var values = [];
  var i;

  for (i = min; i < max; i += step) {
    values.push(prefix + Math.round(dfactor * i) / dfactor);
  }

  return values;
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function months(config) {
  var cfg = config || {};
  var count = cfg.count || 12;
  var section = cfg.section;
  var values = [];
  var i, value;

  for (i = 0; i < count; ++i) {
    value = MONTHS[Math.ceil(i) % 12];
    values.push(value.substring(0, section));
  }

  return values;
}

const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
];

export function color(index) {
  return COLORS[index % COLORS.length];
}

export function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  //return colorLib(value).alpha(alpha).rgbString();
}

export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
];

export function namedColor(index) {
  return NAMED_COLORS[index % NAMED_COLORS.length];
}

export function languageOptions(enableDefaultLanguage: boolean) {

  const langs = {
    [LokaliseFileEnum.en_US]: {
      title: 'language.en_US',
      language: LanguagesEnum.EN,
      lokaliseFile: LokaliseFileEnum.en_US,
      javaLocale: JavaLocaleEnum.EUA,
      flag: FlagEnum.US
    },
    [LokaliseFileEnum.es_419]: {
      title: 'language.es_419',
      language: LanguagesEnum.ES,
      lokaliseFile: LokaliseFileEnum.es_419,
      javaLocale: JavaLocaleEnum.LATAM,
      flag: FlagEnum.LATAM
    },
    [LokaliseFileEnum.en]: {
      title: 'language.default',
      language: LanguagesEnum.EN,
      lokaliseFile: LokaliseFileEnum.en,
      javaLocale: JavaLocaleEnum.EUA,
      flag: FlagEnum.US
    }
  };

  if (enableDefaultLanguage) delete langs[LokaliseFileEnum.en_US];
  if (!enableDefaultLanguage) delete langs[LokaliseFileEnum.en];

  return langs;

}



export function isEmpty(value) {
  return value === null || typeof value === 'undefined' ||  (typeof value === 'string' && value.trim() === '')
}

export function getLocaleByLang(lang): JavaLocaleEnum {
  const languages = languageOptions(environment.enableDefaultLanguage);
  return languages[lang].javaLocale;
}


export function  transformDateToFormatEN(date) {

  let numb = date.match(/\d/g);
  numb = numb.join("");
  const part1 = numb.substr(0, 2);
  const part2 = numb.substr(2, 2);
  const part3 = numb.substr(4, 4);

  return part3 + '-' + part2 + '-' + part1;
}

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}