import { NgModule } from '@angular/core'
import { Router, RouterModule, Routes } from '@angular/router'
import { EmptyComponentComponent } from './main/pages/miscellaneous/empty-component/empty-component.component'
import { RedirectToLoginResolver } from './pay/resolver'
import { NoAuthGuard } from './auth/helpers/no-auth.guards'
import { AuthGuard } from './auth/helpers'

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'resolver',
    pathMatch: 'full',
  },
  {
    path: 'resolver',
    component: EmptyComponentComponent,
    resolve: {
      redirectToLogin: RedirectToLoginResolver,
    },
  },
  {
    path: 'auth',
    loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'purchase',
    loadChildren: () => import('./main/shopper/purchase/purchase.module').then(m => m.PurchaseModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'refunds',
    loadChildren: () => import('./main/shopper/refunds/refunds.module').then(m => m.RefundsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tracking',
    loadChildren: () => import('./main/shopper/tracking/tracking.module').then(m => m.TrackingModule),
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pay/components/checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: 'refund',
    loadChildren: () =>
      import('./public/modules/refund-resolution/refund-resolution.module').then(m => m.RefundResolutionModule),
  },
  {
    path: 'associate-purchase',
    loadChildren: () =>
      import('./pay/components/associate-purchase/associate-purchase.module').then(m => m.AssociatePurchaseModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./main/shopper/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'tiendanube',
    loadChildren: () => import('./main/shopper/tiendanube/tiendanube.module').then(m => m.TiendanubeModule),
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error', //Error 404 - Page not found
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
