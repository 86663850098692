import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, EMPTY, Observable, Subject, Subscription, timer} from 'rxjs';
import {catchError, map, takeUntil, tap} from 'rxjs/operators';
import {Role, User} from 'app/auth/models';
import {environment} from "../../../environments/environment";
import {JwtHelperService} from "@auth0/angular-jwt";
import {SessionStorageService} from './storage.service';
import {Router} from "@angular/router";
import {RequestCode} from "../../../@core/models/requestCode";
import { StorageSessionDataService } from './storage-session-data.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService implements OnDestroy {
    //public
    public currentUser: Observable<User>;

    //private
    private currentUserSubject: BehaviorSubject<User>;
    private jwtHelper: JwtHelperService = new JwtHelperService();

    private _unsubscribeAll: Subject<void> = new Subject<void>();

    /**
     *
     * @param {HttpClient} _http
     * @param _storage
     * @param _storageData
     * @param _router
     */
    constructor(
        private readonly _http: HttpClient,
        private readonly _storage: SessionStorageService,
        private readonly _storageData: StorageSessionDataService,
        private readonly _router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(this._storageData.user);
        this.currentUser = this.currentUserSubject.asObservable();

        console.warn('AuthenticationService#constructor')
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // getter: currentUserValue
    public get currentUserValue(): User {
        return this.currentUserSubject.getValue();
    }

    /**
     * User login
     *
     * @param shopperId
     * @param body
     * @returns user
     */
    login(shopperId, body: { username: string, otp: string }): Observable<any> {
        return this._http.post<any>(`${environment.apiUrl}public/v1/shoppers/token`, body)
            .pipe(
                map(res => {
                    localStorage.setItem('shopperId', shopperId)
                    localStorage.setItem('username', body.username)
                    return res;
                }),
                map(this.handleResponse)
            )
    }

    public handleResponse: (auth) => User = (response) => {
        const decodeToken = this.jwtHelper.decodeToken(response.access_token);
        const user = {
            id: localStorage.getItem('shopperId'),
            email: localStorage.getItem('username'),
            token: response.access_token,
            role: Role.Shopper,
            roles: decodeToken?.realm_access?.roles,
        };
        this._storage.set<string>('access_token', response.access_token)
        this._storage.set<string>('refresh_token', response.refresh_token)
        localStorage.setItem('currentUser', JSON.stringify(user))
        this._scheduleTokenRefresh();
        this.currentUserSubject.next(user)
        return user;
    }

    /**
     * User logout
     *
     */
    public logout(): void {

        this._logoutFromKeycloak().pipe(
            takeUntil(this._unsubscribeAll),
            tap(_ => {
                localStorage.clear();
                this._storage.clear();
                this.currentUserSubject.next(null);
                this._router.navigate(['/']);
            }),
            catchError(error => {
                console.error('Failed _logoutFromKeycloak', error);
                return EMPTY;
            })
        ).subscribe();

    }


    public shopperCodeOTP(shopper: string): Observable<RequestCode> {
        return this._http.post<RequestCode>(`${environment.apiUrl}public/v1/shoppers/requestCode`, shopper, {
            params: {
                notDisplayModalError: true
            }
        })
    }

    private _logoutFromKeycloak(): Observable<null> {
        const refreshToken = this._storage.get<string>('refresh_token');
        if (!refreshToken) return EMPTY;
        let headers = new HttpHeaders();
        headers = headers.set('X-Refresh-Token', refreshToken)
        return this._http.get<null>(`${environment.apiUrl}public/v1/shoppers/logout`, {headers});
    }


    public fetchNewsTokens(): Observable<any> {
        const refreshToken = this._storage.get<string>('refresh_token');
        let headers = new HttpHeaders();
        headers = headers.set('X-Refresh-Token', refreshToken);

        return this._http.get<any>(`${environment.apiUrl}public/v1/shoppers/refresh-token`, {headers,
            params: {'notDisplayModalError': 'true'}})
    }


    public getTokenLifetime() {
        let now: number = new Date().valueOf();
        const access_token = this._storage.get<string>('access_token');
        if (!access_token) return ;
        const expiresIn = this.jwtHelper.decodeToken(access_token).exp;

        let exp: Date = new Date(0);
        exp.setUTCSeconds(expiresIn);

        console.log('token lifetime', exp.valueOf() - now);
        // return 0
        return exp.valueOf() - now
    }

    private refreshSubscription: Subscription;

    private _scheduleTokenRefresh() {
        this._unScheduleTokenRefresh();
        this.refreshSubscription =
            timer(this.getTokenLifetime())
                .subscribe(lifetime => {
                    if (lifetime <= 0) this._updateTokens();
                });
    }

    private _unScheduleTokenRefresh() {
        // Unsubscribe from the refresh
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    private _updateTokens(): void {
        this.fetchNewsTokens()
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(_=> this.handleResponse),
                catchError(error => {
                    console.error('Failed _updateTokens', error);
                    this.logout()
                    return EMPTY;
                })
            )
            .subscribe()
    }
}
