import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from "@angular/common";

import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {CoreConfig} from "../types";
import {strToNumber} from "../utils/helpers.core";
import {CoreConfigService} from "../services/config.service";
import {JavaLocaleEnum} from "../enums";

@Pipe({
    name: 'fiat',
    pure: false
})
export class LocalCurrencyFiatPipe implements PipeTransform {

    private _unsubscribeAll: Subject<any>;
    private _locale: string;

    constructor(private _coreConfigService: CoreConfigService) {
        this._unsubscribeAll = new Subject();
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: CoreConfig) => {
                this._locale = config?.languageConfig?.javaLocale || JavaLocaleEnum.LATAM;
            });
    }

    transform(value: number | string, currency: string = 'USD'): string {
        return formatCurrency(strToNumber(value), this._locale, getCurrencySymbol(currency, 'wide'), currency, '1.2-2');
    }


}
