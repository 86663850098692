import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AccessToken} from "@core/models/checkout-access-token";
import {environment} from "environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {PaymentLink, PaymentLinkOrder, PaymentLinkOrderRequest} from "../interfaces";
import {PaymentLinkStatusEnum} from "../../../@core/enums";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  private readonly _authorization: BehaviorSubject<AccessToken | null> =
    new BehaviorSubject<AccessToken | null>(null);

  public readonly authorization$: Observable<AccessToken | null> =
    this._authorization.asObservable();

  private readonly _useInterceptor: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly _paymentLinkStatusSubject: BehaviorSubject<PaymentLinkStatusEnum> =
      new BehaviorSubject<PaymentLinkStatusEnum>(PaymentLinkStatusEnum.UNKNOWN);

  public paymentLinkStatusObs : Observable<PaymentLinkStatusEnum> = this._paymentLinkStatusSubject.asObservable();

  public set paymentLinkStatus(paymentLinkStatus: PaymentLinkStatusEnum) {
    this._paymentLinkStatusSubject.next(paymentLinkStatus);
  }

  public get paymentLinkStatus(): PaymentLinkStatusEnum {
    return this._paymentLinkStatusSubject.getValue();
  }


  public readonly useInterceptor$: Observable<boolean> =
    this._useInterceptor.asObservable();

  public set useInterceptor(value: boolean) {
    this._useInterceptor.next(value);
  }

  public get useInterceptor(): boolean {
    return this._useInterceptor.getValue();
  }

  constructor(private readonly _http: HttpClient) {}

  public getAccessToken(
    paymentLinkId: string,
    paymentLinkSignature: string
  ): Observable<AccessToken> {
    const headers = new HttpHeaders({
      ["Skip-Interceptor"]: "",
    });

    const url = `${environment.apiZenkiUrl}v1/oauth/tokens/payment-links/${paymentLinkId}/${paymentLinkSignature}`;
    return this._http.post<AccessToken>(url, undefined, { headers , params: {
        notDisplayModalError: true,
      }});
  }

  public getPaymentLink(paymentLinkId: string): Observable<PaymentLink> {
    const url = `${environment.apiZenkiUrl}v1/pay/payment-links/${paymentLinkId}`;
    return this._http.get<PaymentLink>(url,{
      params: {
        notDisplayModalError: true,
      }
    });
  }

  public createPayment(body: PaymentLinkOrderRequest): Observable<PaymentLinkOrder> {
    const url = `${environment.apiZenkiUrl}v1/pay/payment-links`;
    return this._http.post<any>(url, body);
  }

  public _setAuthorization(accessToken: AccessToken): void {
    this._authorization.next(accessToken);
  }
}
