import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'username'
})
export class UsernamePipe implements PipeTransform {
  transform(email: string): any {
    return email
      ?.split('@')
      .map(n => n)[0];
  }
}
