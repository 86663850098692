import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {CoreDirectivesModule} from '@core/directives/core-directives.module';
import {CorePipesModule} from '@core/pipes/pipes.module';
import {TranslateModule} from "@ngx-translate/core";
import {CoreLoadingScreenModule} from "./components/core-loading-screen/core-loading-screen.module";

@NgModule({
  imports: [CommonModule, TranslateModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule, CorePipesModule, CoreLoadingScreenModule],
  exports: [CommonModule, TranslateModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule, CorePipesModule, CoreLoadingScreenModule]
})
export class CoreCommonModule {}
