import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class RedirectToLoginResolver implements Resolve<any> {

    private readonly _routeLogin: string = '/auth/login';
    private readonly _paramPurchase: string = 'purchase';

    constructor(private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const purchaseParam = route.queryParamMap.get(this._paramPurchase);

        if (purchaseParam) {
            const returnUrl = `purchase/sale/${purchaseParam}`;
            this.router.navigate([this._routeLogin], {queryParams: {returnUrl}});
            return false;
        } else {
            this.router.navigate([this._routeLogin]);
        }
        return true;
    }

}
