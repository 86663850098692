import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {StorageSessionDataService} from "../service/storage-session-data.service";
import {SessionStorageService} from "../service/storage.service";


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private readonly _router: Router,
              private readonly _storage: StorageSessionDataService,
              private readonly _sessionStorageService: SessionStorageService) {

  }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentUser = this._storage.user;
    const access_token = this._sessionStorageService.get<string>('access_token');

    if (currentUser && access_token) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(['/pages/miscellaneous/not-authorized']);
        return false;
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['auth/login']);
    return false;
  }

}
