import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreLoadingScreenComponent} from "../core-loading-screen/core-loading-screen.component";



@NgModule({
  declarations: [CoreLoadingScreenComponent],
  imports: [
    CommonModule
  ],
  exports: [CoreLoadingScreenComponent]
})
export class CoreLoadingScreenModule { }
