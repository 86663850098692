
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { I18nSelectComponent } from "./i18n-select.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [I18nSelectComponent],
  imports: [CommonModule, NgbModule, TranslateModule],
  exports: [I18nSelectComponent],
})
export class I18nSelectModule {}
