export const CUSTOMER_ROLE: Readonly<string> = "customer";
export const CUSTOMER_PAY_PUBLIC_ROLE: Readonly<string> = "customer_pay_public";
export const CUSTOMER_PAY_ROLE: Readonly<string> = "customer_pay";
export const CUSTOMER_PAY_STAFF_ROLE: Readonly<string> = "customer_pay_staff";
export const CUSTOMER_PAY_PUBLIC_ROLES: ReadonlyArray<string> = [CUSTOMER_PAY_PUBLIC_ROLE, CUSTOMER_ROLE];
export const CUSTOMER_PAY_ROLES: ReadonlyArray<string> = [CUSTOMER_PAY_ROLE, ...CUSTOMER_PAY_PUBLIC_ROLES];
export const CUSTOMER_PAY_STAFF_ROLES: ReadonlyArray<string> = [CUSTOMER_PAY_STAFF_ROLE, ...CUSTOMER_PAY_ROLES];


export const STAFF_ROLE: Readonly<string> = "staff";
export const SHOPPER_ROLE: Readonly<string> = "shopper";
export const STAFF_ADMIN_ROLE: Readonly<string> = "staff_admin";
export const STAFF_PAY_ROLE: Readonly<string> = "staff_pay";
export const STAFF_PAY_ROLES: ReadonlyArray<string> = [STAFF_PAY_ROLE, STAFF_ROLE];
export const STAFF_ADMIN_ROLES: ReadonlyArray<string> = [STAFF_ADMIN_ROLE, ...STAFF_PAY_ROLES];

