import { Component } from '@angular/core';

@Component({
  selector: 'core-loading-screen',
  templateUrl: './core-loading-screen.component.html',
  styleUrls: ['./core-loading-screen.component.css']
})
export class CoreLoadingScreenComponent {

}
