import { Role } from './role';

export class User {
  id: string | number;
  email: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  role?: Role;
  roles?: string[];
  token?: string;
  fullName?:string;

  constructor(user: Partial<User>) {
    Object.assign(this, user)
    this.fullName = `${user.firstName} ${user.lastName}`
  }
}
