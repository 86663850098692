import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {TranslateLanguageConfig} from "../interfaces/translate-language-config";
import {LocaleService} from "./locale.service";
import {JavaLocaleEnum, LokaliseFileEnum} from "../enums";
import {languageOptions} from "../../app/utils";


@Injectable({
  providedIn: 'root'
})
export class CurrentLangService {

  private _translateConfig: TranslateLanguageConfig;

  constructor(private readonly _translateService: TranslateService,
              private readonly _localeService: LocaleService) {
  }

  public initialSelectionLangs(enableDefaultLanguage: boolean) {

    const languageOptionsJson = languageOptions(enableDefaultLanguage);
    this._translateService.addLangs(Object.keys(languageOptionsJson));
    this.refreshLanguageConfigStorage();
    const lokaliseFileLang = this._getLangToUse(enableDefaultLanguage);
    this._translateService.setDefaultLang(lokaliseFileLang);
    this._localeService.setLocale(this._translateConfig?.javaLocale ? this._translateConfig?.javaLocale : JavaLocaleEnum.LATAM);

  }

  private refreshLanguageConfigStorage() {
    this._translateConfig = JSON.parse(localStorage.getItem('languageConfig'));
  }

  private _getLangToUse(enableDefaultLanguage) {

    let lang = LokaliseFileEnum.es_419;
    const actualLang = this._translateConfig?.lokaliseFile
      ? this._translateConfig.lokaliseFile
      : enableDefaultLanguage
        ? LokaliseFileEnum.es_419
        : this._translateConfig?.lokaliseFile;
    const existLang = this._translateService.getLangs().includes(actualLang);

    if (enableDefaultLanguage) {
      lang = existLang ? actualLang : LokaliseFileEnum.en;
    }
    if (!enableDefaultLanguage) {
      lang = existLang ? actualLang : LokaliseFileEnum.en_US;
    }

    return lang;

  }


  public _updateAppLang(enableDefaultLanguage) {
    this.refreshLanguageConfigStorage();
    const lokaliseFileLang = this._getLangToUse(enableDefaultLanguage);
    this._translateService.use(lokaliseFileLang);
  }


}
