import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const httpRequest = req.clone({
      headers: req.headers
          .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
          .set('Pragma', 'no-cache')
          .set('Expires', '0')
    });

    return next.handle(httpRequest);
  }
}