export enum OrderRefundStatusEnum {
  CREATED = 'CREATED',
  REQUESTED = 'REQUESTED',
  PENDING_NOT_ENOUGH = 'PENDING_NOT_ENOUGH',
  SWAP_ROLLBACK_REQUESTED = 'SWAP_ROLLBACK_REQUESTED',
  SWAP_ROLLBACK_COMPLETED = 'SWAP_ROLLBACK_COMPLETED',
  SWAP_ROLLBACK_PENDING = 'SWAP_ROLLBACK_PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  TRANSFER_ERROR = 'TRANSFER_ERROR',
  FAILED = 'FAILED',
  PENDING_MERCHANT_ACTION = 'PENDING_MERCHANT_ACTION',
  REJECTED_BY_SHOPPER = 'REJECTED_BY_SHOPPER',
  PENDING_SHOPPER_APPROVAL = 'PENDING_SHOPPER_APPROVAL',
}
