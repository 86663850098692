import { TranslateService } from "@ngx-translate/core";
import {Cryptocurrency} from "../../models/cryptocurrency";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Input = any;
export type TotalColsFn = (width: number) => number;

/**
 * Compare if two objects are strictly equals.
 * @param {any} input1 to compare.
 * @param {any} input2 to compare.
 * @param {number} deepLevelLimit optional, it prevents infinite recursion in circular objects.
 * @returns {boolean} `true` if these are strictly equal.
 */
export function isEqual(
  input1: Input,
  input2: Input,
  deepLevelLimit = 7
): boolean {
  function _isEqual(
    param1: Input,
    param2: Input,
    currentDeepLevel: number
  ): boolean {
    if (currentDeepLevel > deepLevelLimit) {
      throw new Error(
        `Comparison between objects exceeds deep level limit of ${deepLevelLimit}`
      );
    }

    if ((!param1 && param2) || (param1 && !param2)) return false;

    if (!param1 && !param2) return true;

    if (typeof param1 !== typeof param2) return false;

    if (typeof param1 !== 'object') {
      return param1 === param2;
    }

    if (Array.isArray(param1) && Array.isArray(param2)) {
      for (let i = 0; i < param1.length; i++) {
        const isOK: boolean = _isEqual(
          param1[i],
          param2[i],
          currentDeepLevel + 1
        );
        if (!isOK) return false;
      }
    } else if (
      (Array.isArray(param1) && !Array.isArray(param2)) ||
      (!Array.isArray(param1) && Array.isArray(param2))
    ) {
      return false;
    }

    const param1Entries: [string, Input][] = Object.entries(param1);
    const param2Entries: [string, Input][] = Object.entries(param2);

    if (param1Entries.length !== param2Entries.length) return false;

    for (let i = 0; i < param1Entries.length; i++) {
      const [key1, value1]: [string, Input] = param1Entries[i];
      const index: number = param2Entries.findIndex(
        ([key2]: [string, Input]): boolean => key2 === key1
      );
      if (index < 0) return false;

      const [, value2]: [string, Input] = param2Entries.splice(index, 1)[0];
      const isOK: boolean = _isEqual(value1, value2, currentDeepLevel + 1);
      if (!isOK) return false;
    }

    return true;
  }

  return _isEqual(input1, input2, 0);
}

/**
 * Clone an object.
 * @param {any} input to clone.
 * @returns {any} clone.
 */
export function clone<T = unknown>(input: T): T {
  try {
    return JSON.parse(JSON.stringify(input));
  } catch (_) {
    return input;
  }
}

/**
 * Parse string to JSON.
 * @param {string} input to parse.
 * @returns {any} value parsed as JSON.
 */
export function string2Json<T = unknown>(input: string): T {
  try {
    return JSON.parse(input);
  } catch (_) {
    return input as unknown as T;
  }
}

/**
 * Parse JSON to string.
 * @param {any} input to stringify.
 * @returns {string} Input value stringified.
 */
export function json2String<T = unknown>(input: T): string {
  try {
    if (typeof input === 'string') return input;
    return JSON.stringify(input);
  } catch (_) {
    return input as unknown as string;
  }
}

/**
 * Return crypto sswap text
 * @param { Cryptocurrency } crypto to generate text.
 * @param { TranslateService } translate transalate i18n service.
 * @returns { string }
 */
export function getCryptoSwapText(crypto: Cryptocurrency, translate: TranslateService): string {
  const I18N_PREFIX = 'core.common.components.crypto';
  if (crypto.isSwap && crypto.swap) {
    return `${translate.instant(`${I18N_PREFIX}.swap`)} ${crypto.swap.symbol}`;
  }
  return `${translate.instant(`${I18N_PREFIX}.keep`)} ${crypto.symbol}`;
}


export function calculateTotalColsForTwoCols(width: number): number {
  const WIDTH_1_COL = 500;

  if (width < WIDTH_1_COL) return 1;
  return 2;
}

export function calculateTotalColsForThreeCols(width: number): number {
  const WIDTH_1_COL = 400;
  const WIDTH_2_COLS = 550;

  if (width < WIDTH_1_COL) return 1;
  if (width < WIDTH_2_COLS) return 2;
  return 3;
}

export function copy(value) {
  const selectBox = document.createElement('textarea');
  selectBox.style.position = 'fixed';
  selectBox.value = value;
  document.body.appendChild(selectBox);
  selectBox.focus();
  selectBox.select();
  document.execCommand('copy');
  document.body.removeChild(selectBox);
  // setTimeout((): void => {
    // this.copyCodeStatus = false;
  // }, 800);
  // this.copyCodeStatus = true;
}

export function roundAmount(num):number {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
}

export function UUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if(d > 0){//Use timestamp until depleted
      r = (d + r)%16 | 0;
      d = Math.floor(d/16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r)%16 | 0;
      d2 = Math.floor(d2/16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}
