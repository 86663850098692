<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
    <!-- Navbar brand -->
    <ul class="nav navbar-nav flex-row">
        <li class="nav-item">
            <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="178"/>
        </span>
            </a>
        </li>
    </ul>
    <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center center-elements">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
            <li class="nav-item">
                <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                    <span [feather-icon]="'menu'" [class]="'ficon'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->

    </div>


    <ul class="nav navbar-nav align-items-center ml-auto">

        <div class="px-2 app-version-menu" *ngIf="appVersion"> v.{{appVersion}}</div>
        <div class="px-2 app-version-menu" *ngIf="!appVersion">no version!</div>

        <!-- Language selection -->
        <app-i18n-select class="i18n"></app-i18n-select>
        <!--/ Language selection -->

        <!-- User Dropdown -->
        <li ngbDropdown class="nav-item dropdown-user">
            <a
              class="nav-link dropdown-toggle dropdown-user-link"
              ngbDropdownToggle
              id="navbarUserDropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >

              <span class="card-logo">
                <img class="img-logo"
                  src="/assets/images/icons/shop-bag.svg"
                  height="25"
                  width="25"/>
              </span>
              <i class="fas fa-bars botton"></i>

            </a>
            <app-navbar-menu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right"
              ngbDropdownMenu
              (logoutEvent)="logout()"
              (closeEvent)="closeMenu()">
            </app-navbar-menu>
        </li>
        <!--/ User Dropdown -->
    </ul>
</div>
