import {PaymentLinkStatusEnum} from "../../enums";


export interface ErrorCodes {
    code: number;
    status: PaymentLinkStatusEnum
}

const errorCodes: ErrorCodes[] =
    [
        {code: 406, status: PaymentLinkStatusEnum.PAID},
        {code: 409, status: PaymentLinkStatusEnum.LOCKED},
        {code: 422, status: PaymentLinkStatusEnum.OUT_PAYMENT_RANGE},
        {code: 428, status: PaymentLinkStatusEnum.EXPIRED}
    ];


export function getFailedPaymentLinkStatus(errorNumber: number): PaymentLinkStatusEnum {
    const err = errorCodes.find(codes => codes?.code === errorNumber)
    return err?.status;
}
