import {Pipe, PipeTransform} from '@angular/core';

import {DatePipe} from "@angular/common";
import {LocaleService} from "../services/locale.service";
import {JavaLocaleEnum} from "../enums";

/**
 * Usage: dateString| localDate: 'format: string' : 'time_patterns: string': enableDefaultLocaleFormat: boolean : 'timezone: string'
 * value | localDate: '': 'h:mm a': true: 'UTC-5'
 * value | localDate: 'M/d/yy, h:mm a': '': false: 'UTC-6'
 **/

@Pipe({
  name: 'localDate',
  pure: false
})
export class LocalDatePipe implements PipeTransform {

    constructor(private _localeService: LocaleService) {
    }

    transform(value: any, format: string, time_patterns?: string, enableDefaultLocaleFormat?: boolean, timezone?: string): any {
        const locale = this._localeService.actualLocale ? this._localeService.actualLocale : JavaLocaleEnum.LATAM;

        if (!value) {
            return '';
        }
        if (!format && !enableDefaultLocaleFormat) {
            format = 'mediumDate';
        }

        if (enableDefaultLocaleFormat && !format) {
            format = this._localeService.getFormatDateByLocaleCode(locale)
        }

        if (enableDefaultLocaleFormat && time_patterns) {
            format = this._localeService.getFormatDateByLocaleCodeAndTimePatterns(locale, time_patterns)
        }
        if (!timezone) timezone = this.timezone

        const datePipe = new DatePipe(locale);
        return datePipe.transform(value, format, timezone);
    }

    get timezone():string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }

}
