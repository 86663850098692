import {Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as Waves from 'node-waves';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreLoadingScreenService} from '@core/services/loading-screen.service';
import {CoreConfig, CoreMenu} from "../@core/types";
import {LocaleService} from "../@core/services/locale.service";
import {environment} from "../environments/environment";
import {CurrentLangService} from "../@core/services/current-lang.service";
import {StorageSessionDataService} from "./auth/service/storage-session-data.service";
import {coreConfig} from './app-config';
import {menu} from 'app/menu/menu';
import {CoreMenuService} from "../@core/components/core-menu/core-menu.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    public coreConfig!: CoreConfig;
    public menu!: CoreMenu[];

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(@Inject(DOCUMENT) private document: any,
                private _title: Title,
                private _renderer: Renderer2,
                private _elementRef: ElementRef,
                public _coreConfigService: CoreConfigService,
                private _coreSidebarService: CoreSidebarService,
                private _coreLoadingScreenService: CoreLoadingScreenService,
                private _localeService: LocaleService,
                private readonly _currentLangService: CurrentLangService,
                private readonly _storageSessionData: StorageSessionDataService,
                private readonly _coreMenuService: CoreMenuService) {

        //Load all active Languages
        this._currentLangService.initialSelectionLangs(environment?.enableDefaultLanguage);

        if (!this._storageSessionData?.languageConfig) this._setDefaultLangStorage();

        // Get the application main menu
        // enableProdMode()
        this.menu = menu;

        // Register the menu to the menu service
        this._coreMenuService.register('main', this.menu);

        // Set the main menu as our current menu
        this._coreMenuService.setCurrentMenu('main');


        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }



// Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Init wave effect (Ripple effect)
        Waves.init();

        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.coreConfig = config;

                //Update Language
                this._currentLangService._updateAppLang(environment?.enableDefaultLanguage);

                // Layout
                //--------

                // Remove default classes first
                this._elementRef.nativeElement.classList.remove(
                    'vertical-layout',
                    'vertical-menu-modern',
                    'horizontal-layout',
                    'horizontal-menu'
                );
                // Add class based on config options
                if (this.coreConfig.layout.type === 'vertical') {
                    this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
                } else if (this.coreConfig.layout.type === 'horizontal') {
                    this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
                }

                // Navbar
                //--------

                // Remove default classes first
                this._elementRef.nativeElement.classList.remove(
                    'navbar-floating',
                    'navbar-static',
                    'navbar-sticky',
                    'navbar-hidden'
                );

                // Add class based on config options
                if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
                    this._elementRef.nativeElement.classList.add('navbar-static');
                } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
                    this._elementRef.nativeElement.classList.add('navbar-sticky');
                } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
                    this._elementRef.nativeElement.classList.add('navbar-floating');
                } else {
                    this._elementRef.nativeElement.classList.add('navbar-hidden');
                }

                // Footer
                //--------

                // Remove default classes first
                this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

                // Add class based on config options
                if (this.coreConfig.layout.footer.type === 'footer-sticky') {
                    this._elementRef.nativeElement.classList.add('footer-fixed');
                } else if (this.coreConfig.layout.footer.type === 'footer-static') {
                    this._elementRef.nativeElement.classList.add('footer-static');
                } else {
                    this._elementRef.nativeElement.classList.add('footer-hidden');
                }

                // Blank layout
                if (
                    this.coreConfig.layout.menu.hidden &&
                    this.coreConfig.layout.navbar.hidden &&
                    this.coreConfig.layout.footer.hidden
                ) {
                    this._elementRef.nativeElement.classList.add('blank-page');
                    // ! Fix: Transition issue while coming from blank page
                    setTimeout(() => {
                        this._renderer.setAttribute(
                            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
                            'style',
                            'transition:none'
                        );
                    });
                } else {
                    this._elementRef.nativeElement.classList.remove('blank-page');
                    // ! Fix: Transition issue while coming from blank page
                    setTimeout(() => {
                        this._renderer.setAttribute(
                            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
                            'style',
                            'transition:300ms ease all'
                        );
                    });
                    // If navbar hidden
                    if (this.coreConfig.layout.navbar.hidden) {
                        this._elementRef.nativeElement.classList.add('navbar-hidden');
                    }
                    // Menu (Vertical menu hidden)
                    if (this.coreConfig.layout.menu.hidden) {
                        this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
                    } else {
                        this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
                    }
                    // Footer
                    if (this.coreConfig.layout.footer.hidden) {
                        this._elementRef.nativeElement.classList.add('footer-hidden');
                    }
                }

                // Skin Class (Adding to body as it requires highest priority)
                if (this.coreConfig.layout.skin !== undefined) {
                    this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
                    this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
                }
            });

        // Set the application page title
        this._title.setTitle(this.coreConfig.app.appTitle);
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebar(key): void {
        this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
    }


    // Private Methods
    // -----------------------------------------------------------------------------------------------------

    private _setDefaultLangStorage() {
        this._storageSessionData.languageConfig = coreConfig.languageConfig;
    }

}
