/**
 * @namespace Chart.helpers
 */
/**
 * An empty function that can be used, for example, for optional callback.
 */
export function noop() {}

/**
 * Returns a unique id, sequentially generated from a global variable.
 * @returns {number}
 * @function
 */
export const uid = (function() {
    let id = 0;
    return function() {
        return id++;
    };
}());

/**
 * Returns true if `value` is neither null nor undefined, else returns false.
 * @param {*} value - The value to test.
 * @returns {boolean}
 * @since 2.7.0
 */
export function isNullOrUndefOrEmpty(value) {
    return value === null || typeof value === 'undefined' || value === '';
}

/**
 * Returns true if `value` is an array (including typed arrays), else returns false.
 * @param {*} value - The value to test.
 * @returns {boolean}
 * @function
 */
export function isArray(value) {
    if (Array.isArray && Array.isArray(value)) {
        return true;
    }
    const type = Object.prototype.toString.call(value);
    if (type.substr(0, 7) === '[object' && type.substr(-6) === 'Array]') {
        return true;
    }
    return false;
}

/**
 * Returns true if `value` is an object (excluding null), else returns false.
 * @param {*} value - The value to test.
 * @returns {boolean}
 * @since 2.7.0
 */
export function isObject(value) {
    return value !== null && Object.prototype.toString.call(value) === '[object Object]';
}

/**
 * Returns true if `value` is a finite number, else returns false
 * @param {*} value  - The value to test.
 * @returns {boolean}
 */
const isNumberFinite = (value) => (typeof value === 'number' || value instanceof Number) && isFinite(+value);
export {
    isNumberFinite as isFinite,
};

/**
 * Returns `value` if finite, else returns `defaultValue`.
 * @param {*} value - The value to return if defined.
 * @param {*} defaultValue - The value to return if `value` is not finite.
 * @returns {*}
 */
export function finiteOrDefault(value, defaultValue) {
    return isNumberFinite(value) ? value : defaultValue;
}

/**
 * Returns `value` if defined, else returns `defaultValue`.
 * @param {*} value - The value to return if defined.
 * @param {*} defaultValue - The value to return if `value` is undefined.
 * @returns {*}
 */
export function valueOrDefault(value, defaultValue) {
    return typeof value === 'undefined' ? defaultValue : value;
}

export const toPercentage = (value, dimension) =>
    typeof value === 'string' && value.endsWith('%') ?
        parseFloat(value) / 100
        : value / dimension;

export const toDimension = (value, dimension) =>
    typeof value === 'string' && value.endsWith('%') ?
        parseFloat(value) / 100 * dimension
        : +value;

/**
 * Calls `fn` with the given `args` in the scope defined by `thisArg` and returns the
 * value returned by `fn`. If `fn` is not a function, this method returns undefined.
 * @param {function} fn - The function to call.
 * @param {Array|undefined|null} args - The arguments with which `fn` should be called.
 * @param {object} [thisArg] - The value of `this` provided for the call to `fn`.
 * @returns {*}
 */
export function callback(fn, args, thisArg) {
    if (fn && typeof fn.call === 'function') {
        return fn.apply(thisArg, args);
    }
}

/**
 * Note(SB) for performance sake, this method should only be used when loopable type
 * is unknown or in none intensive code (not called often and small loopable). Else
 * it's preferable to use a regular for() loop and save extra function calls.
 * @param {object|Array} loopable - The object or array to be iterated.
 * @param {function} fn - The function to call for each item.
 * @param {object} [thisArg] - The value of `this` provided for the call to `fn`.
 * @param {boolean} [reverse] - If true, iterates backward on the loopable.
 */
export function each(loopable, fn, thisArg, reverse) {
    let i, len, keys;
    if (isArray(loopable)) {
        len = loopable.length;
        if (reverse) {
            for (i = len - 1; i >= 0; i--) {
                fn.call(thisArg, loopable[i], i);
            }
        } else {
            for (i = 0; i < len; i++) {
                fn.call(thisArg, loopable[i], i);
            }
        }
    } else if (isObject(loopable)) {
        keys = Object.keys(loopable);
        len = keys.length;
        for (i = 0; i < len; i++) {
            fn.call(thisArg, loopable[keys[i]], keys[i]);
        }
    }
}

/**
 * Returns true if the `a0` and `a1` arrays have the same content, else returns false.
 * @param {Array} a0 - The array to compare
 * @param {Array} a1 - The array to compare
 * @returns {boolean}
 * @private
 */
export function _elementsEqual(a0, a1) {
    let i, ilen, v0, v1;

    if (!a0 || !a1 || a0.length !== a1.length) {
        return false;
    }

    for (i = 0, ilen = a0.length; i < ilen; ++i) {
        v0 = a0[i];
        v1 = a1[i];

        if (v0.datasetIndex !== v1.datasetIndex || v0.index !== v1.index) {
            return false;
        }
    }

    return true;
}

/**
 * Returns a deep copy of `source` without keeping references on objects and arrays.
 * @param {*} source - The value to clone.
 * @returns {*}
 */
export function clone(source) {
    if (isArray(source)) {
        return source.map(clone);
    }

    if (isObject(source)) {
        const target = Object.create(null);
        const keys = Object.keys(source);
        const klen = keys.length;
        let k = 0;

        for (; k < klen; ++k) {
            target[keys[k]] = clone(source[keys[k]]);
        }

        return target;
    }

    return source;
}

function isValidKey(key) {
    return ['__proto__', 'prototype', 'constructor'].indexOf(key) === -1;
}

/**
 * The default merger when Chart.helpers.merge is called without merger option.
 * Note(SB): also used by mergeConfig and mergeScaleConfig as fallback.
 * @private
 */
export function _merger(key, target, source, options) {
    if (!isValidKey(key)) {
        return;
    }

    const tval = target[key];
    const sval = source[key];

    if (isObject(tval) && isObject(sval)) {
        // eslint-disable-next-line no-use-before-define
        merge(tval, sval, options);
    } else {
        target[key] = clone(sval);
    }
}

/**
 * Recursively deep copies `source` properties into `target` with the given `options`.
 * IMPORTANT: `target` is not cloned and will be updated with `source` properties.
 * @param {object} target - The target object in which all sources are merged into.
 * @param {object|object[]} source - Object(s) to merge into `target`.
 * @param {object} [options] - Merging options:
 * @param {function} [options.merger] - The merge method (key, target, source, options)
 * @returns {object} The `target` object.
 */
export function merge(target, source, options) {
    const sources = isArray(source) ? source : [source];
    const ilen = sources.length;

    if (!isObject(target)) {
        return target;
    }

    options = options || {};
    const merger = options.merger || _merger;

    for (let i = 0; i < ilen; ++i) {
        source = sources[i];
        if (!isObject(source)) {
            continue;
        }

        const keys = Object.keys(source);
        for (let k = 0, klen = keys.length; k < klen; ++k) {
            merger(keys[k], target, source, options);
        }
    }

    return target;
}

/**
 * Recursively deep copies `source` properties into `target` *only* if not defined in target.
 * IMPORTANT: `target` is not cloned and will be updated with `source` properties.
 * @param {object} target - The target object in which all sources are merged into.
 * @param {object|object[]} source - Object(s) to merge into `target`.
 * @returns {object} The `target` object.
 */
export function mergeIf(target, source) {
    // eslint-disable-next-line no-use-before-define
    return merge(target, source, {merger: _mergerIf});
}

/**
 * Merges source[key] in target[key] only if target[key] is undefined.
 * @private
 */
export function _mergerIf(key, target, source) {
    if (!isValidKey(key)) {
        return;
    }

    const tval = target[key];
    const sval = source[key];

    if (isObject(tval) && isObject(sval)) {
        mergeIf(tval, sval);
    } else if (!Object.prototype.hasOwnProperty.call(target, key)) {
        target[key] = clone(sval);
    }
}

/**
 * @private
 */
export function _deprecated(scope, value, previous, current) {
    if (value !== undefined) {
        console.warn(scope + ': "' + previous +
            '" is deprecated. Please use "' + current + '" instead');
    }
}

const emptyString = '';
const dot = '.';
function indexOfDotOrLength(key, start) {
    const idx = key.indexOf(dot, start);
    return idx === -1 ? key.length : idx;
}

export function resolveObjectKey(obj, key) {
    if (key === emptyString) {
        return obj;
    }
    let pos = 0;
    let idx = indexOfDotOrLength(key, pos);
    while (obj && idx > pos) {
        obj = obj[key.substr(pos, idx - pos)];
        pos = idx + 1;
        idx = indexOfDotOrLength(key, pos);
    }
    return obj;
}

/**
 * @private
 */
export function _capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}


export const defined = (value) => typeof value !== 'undefined';

export const isFunction = (value) => typeof value === 'function';

// Adapted from https://stackoverflow.com/questions/31128855/comparing-ecma6-sets-for-equality#31129384
export const setsEqual = (a, b) => {
    if (a.size !== b.size) {
        return false;
    }

    for (const item of a) {
        if (!b.has(item)) {
            return false;
        }
    }

    return true;
};

export function cleanObject(obj): any {
    Object.keys(obj).map(((e) => isNullOrUndefOrEmpty(obj[e]) ? delete obj[e] : null))
    return obj;
}

export function majorZero(value: number): boolean {
    return (value > 0);
}