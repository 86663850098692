<!-- Vertical Menu -->
<ng-container *ngIf="layout === 'vertical' && currentUser">
  <ng-container *ngFor="let item of menu">
    <!-- section -->
    <li core-menu-vertical-section
        *ngIf="item.type === 'section' && (item | showElementIfHasRole:currentUser)"
        [item]="item"
        class="navigation-header">
    </li>

    <!-- sub-menu item/collapsible of sections  -->
    <ng-container *ngIf="item.type === 'section'">
      <ng-container *ngFor="let item of item.children">
        <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
        <li core-menu-vertical-item
            *ngIf="item.type === 'item' && (item | showElementIfHasRole:currentUser)"
            [item]="item"
            [ngClass]="{ disabled: item.disabled === true }"
            [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
            [routerLinkActiveOptions]=" item | routerLinkActiveOptions"
        >
          <!-- Fix: Required routerLink to identify the active -->
          <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
        </li>
        <!-- collapsible -->
        <li core-menu-vertical-collapsible
            *ngIf="item.type === 'collapsible' && (item | showElementIfHasRole:currentUser)"
            [item]="item"
            [currentUser]="currentUser"
            class="nav-item has-sub">
        </li>
      </ng-container>
    </ng-container>

    <!-- item -->
    <li core-menu-vertical-item *ngIf="item.type === 'item' && (item | showElementIfHasRole:currentUser)"
        [item]="item"
        [ngClass]="{ disabled: item.disabled === true }"
        [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
        [routerLinkActiveOptions]=" item | routerLinkActiveOptions"
    >
      <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
    </li>
    <!-- collapsible -->

    <li core-menu-vertical-collapsible
        *ngIf="item.type === 'collapsible' && (item | showElementIfHasRole:currentUser)"
        [item]="item"
        [currentUser]="currentUser"
        class="nav-item has-sub"
    ></li>
  </ng-container>
</ng-container>
<!--/ Vertical Menu -->

<!-- Horizontal Menu -->
<ng-container *ngIf="layout === 'horizontal' && currentUser">
  <ng-container *ngFor="let item of menu">
    <!-- section -->
    <li core-menu-horizontal-collapsible
        *ngIf="item.type === 'section' && (item | showElementIfHasRole:currentUser)"
        [item]="item"
        [currentUser]="currentUser"
        class="dropdown nav-item">
    </li>

    <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
    <li core-menu-horizontal-item
        *ngIf="item.type === 'item' && (item | showElementIfHasRole:currentUser)"
        [item]="item"
        [ngClass]="{ disabled: item.disabled === true }"
        [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
        [routerLinkActiveOptions]=" item | routerLinkActiveOptions"
    >
      <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
    </li>

    <!-- collapsible -->
    <li core-menu-horizontal-collapsible
        *ngIf="item.type === 'collapsible' && (item | showElementIfHasRole:currentUser)"
        [item]="item"
        [currentUser]="currentUser"
        class="dropdown dropdown-submenu">
    </li>
  </ng-container>
</ng-container>
<!--/ Horizontal Menu -->
