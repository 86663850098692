import {Component, OnInit, Input} from '@angular/core';
import {Breadcrumb} from "./breadcrumb/breadcrumb.component";

// ContentHeader component interface
export interface ContentHeader {
  headerTitle: string;
  actionButton?: boolean;
  breadcrumb?: Breadcrumb;
  backButton?: {
    link?: string,
    show?: boolean
  }
}

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styles: [
    `
        .content-header .btn-icon {
            padding: 0 0.386rem !important;
            border: 0!important;
            box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)
        }
    `
  ]
})
export class ContentHeaderComponent implements OnInit {
  // input variable
  @Input() contentHeader: ContentHeader;
  @Input() column:string = 'col-12'

  constructor() {
  }

  ngOnInit() {
  }
}
