import { InjectionToken } from "@angular/core";
import { Observable, fromEvent, debounceTime, map, distinctUntilChanged } from "rxjs";

export const WINDOW: InjectionToken<Window> = new InjectionToken('WINDOW');
export interface WindowSize {
  height: number;
  width: number;
}

/**
 * Get window resize changes by an observable.
 * @param {Window} windowRef you must set it by DI.
 * @param {number} delayTime (optional) default value is `500`.
 * @returns {Observable<WindowSize>} Window resize changes.
 */
export function getWindowResizeObservable(
  windowRef: Window,
  delayTime = 500
): Observable<WindowSize> {
  return fromEvent<Event>(windowRef, 'resize').pipe(
    debounceTime(delayTime),
    map(({ target }: Event): Window => target as Window),
    map(
      ({ innerHeight, innerWidth }: Window): WindowSize => ({
        height: innerHeight,
        width: innerWidth,
      })
    ),
    distinctUntilChanged()
  );
}