<li ngbDropdown class="nav-item dropdown dropdown-language">
  <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
    <span *ngIf="_translateService.currentLang !== lokaliseFile.es_419" class="custom-new-flag">
      <i class="flag-icon flag-icon-{{ languageOptions[_translateService?.currentLang].flag }}"></i>
    </span>
    <span *ngIf="_translateService.currentLang === lokaliseFile.es_419" class="custom-new-flag">
      <i class="fa fa-earth-americas"></i>
    </span>
    <span class="selected-language" [innerHtml]="languageOptions[_translateService.currentLang].title | translate"></span>
  </a>
  <div ngbDropdownMenu aria-labelledby="dropdown-flag">
    <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
      <span *ngIf="lang !== lokaliseFile.es_419">
        <i class="flag-icon flag-icon-{{ languageOptions[lang]?.flag }}"></i>
      </span>
      <span *ngIf="lang === lokaliseFile.es_419" class="custom-new-flag">
        <i class="fa fa-earth-americas"></i>
      </span>

      <span class="dropdown-options" [innerHtml]="languageOptions[lang]?.title | translate"></span>
    </a>
  </div>
</li>
