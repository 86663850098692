import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import { User } from '../models';
import { ProfileType } from '@core/enums/profile-type';
import { Observable, Subject } from 'rxjs';
import { StorageSessionDataService } from './storage-session-data.service';


@Injectable({
  providedIn: 'root'
})
export class ImgService {
  private readonly _avatarUpdated: Subject<boolean> = new Subject<boolean>();
  public readonly $avatarUpdated = this._avatarUpdated.asObservable();
  
  public maxFileSize = 10 * 1024 * 1024;
  public avatarImageExist = false;
  public maxFileSizeMB = 10;
  public srcUrl: string;
  public currentUser: User;
  public profileType: ProfileType;

  constructor(private _http: HttpClient,
              private _sessionStorage: StorageSessionDataService) {
  }

  public set avatarUpdated(value: boolean) {
    this._avatarUpdated.next(value);
  }

  getAvatarImage(): Observable<Blob> {
    const { personId } = this._sessionStorage.person;
    return this._http.get<Blob>(`${environment.apiUrl}v1/shoppers/avatars/${personId}`, {responseType: 'blob' as 'json', params: {'notDisplayModalError': 'true'}});
  }

  saveImgPerson(file: any): Observable<any> {
    const { personId } = this._sessionStorage.person;
    const body: FormData = new FormData();
    body.append('image', file, file?.name);
    return this._http.post<any>(`${environment.apiUrl}v1/shoppers/avatars/${personId}`, body);
  }

}
