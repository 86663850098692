import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {StorageSessionDataService} from "../service/storage-session-data.service";
import {JavaLocaleEnum} from "../../../@core/enums";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    constructor(private _storageSessionData: StorageSessionDataService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        req = req.clone({
            setHeaders: {
                'Accept-Language': this._storageSessionData.languageConfig?.javaLocale
                    ? this._storageSessionData.languageConfig?.javaLocale + ',' + JavaLocaleEnum.LATAM + ';q=0.8,*;q=0.7'
                    : JavaLocaleEnum.EUA + ';q=0.8,*;q=0.7',
                'Content-Language': this._storageSessionData.languageConfig?.javaLocale
                    ? this._storageSessionData.languageConfig?.javaLocale + ',' + JavaLocaleEnum.LATAM + ';q=0.8,*;q=0.7'
                    : JavaLocaleEnum.EUA + ';q=0.8,*;q=0.7',
            }
        });
        return next.handle(req);
    }
}

