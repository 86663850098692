import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';
import { Observable, Subject } from 'rxjs';
import { Person } from '../models/person';
import { StorageSessionDataService } from './storage-session-data.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly _personUpdated: Subject<boolean> = new Subject<boolean>();
  public readonly $personUpdated = this._personUpdated.asObservable();
  
  /**
   *
   * @param {HttpClient} _http
   * @param _sessionStorage
   */
  constructor(private readonly _http: HttpClient,
              private readonly _sessionStorage: StorageSessionDataService) {}

  public set personUpdated(value: boolean) {
    this._personUpdated.next(value);
  }

  /**
   * Get all users
   */
  getAll() {
    return this._http.get<User[]>(`${environment.apiUrl}users`);
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${environment.apiUrl}users/${id}`);
  }

  public getShopperInfo(): Observable<Person> {
    return this._http.get<Person>(`${environment.apiUrl}v1/shoppers/${localStorage.getItem('shopperId')}`);
  }

  getAllCountries(pagination: any): Observable<any> {
    return this._http.get(`${environment.apiUrl}public/v1/shoppers/countries`, {params: pagination})
  }

  updatePersonInfo(body): Observable<Person> {
    const { personId } = this._sessionStorage.person;
    return this._http.put<Person>(`${environment.apiUrl}v1/shoppers/${personId}`, body, {params: {'notDisplayModalError': 'true'}});
  }
}
