import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {JavaLocaleEnum} from "../enums";

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private readonly _onChangeLocale: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  public setLocale(keyFlag) {
    this._onChangeLocale.next(keyFlag)
  }

  public get actualLocale() {
    return this._onChangeLocale.getValue();
  }

  public getFormatDateByLocaleCode(localeCode: string) {

    let format;
    switch (localeCode) {
      case JavaLocaleEnum.EUA:
        format = 'MM-dd-YYYY';
        break;
      case JavaLocaleEnum.LATAM:
        format = 'dd/MM/YYYY';
        break;
      case JavaLocaleEnum.ARGENTINA:
        format = 'dd/MM/YYYY';
        break;
      default:
        format = 'MM-dd-YYYY';
    }

    return format
  }


  public getFormatDateByLocaleCodeAndTimePatterns(localeCode: string, time_patterns: string ) {

    let format;
    switch (localeCode) {
      case JavaLocaleEnum.EUA:
        format = 'MM-dd-YYYY, ' + time_patterns;
        break;
      case JavaLocaleEnum.LATAM:
        format = 'dd/MM/YYYY, ' + time_patterns;
        break;
      case JavaLocaleEnum.ARGENTINA:
        format = 'dd/MM/YYYY, ' + time_patterns;
        break;
      default:
        format = 'MM-dd-YYYY, ' + time_patterns;
    }

    return format
  }

}
