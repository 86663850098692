export function getParams(url: string): Record<string, string> {
    const params: Record<string, string> = {};
    const queryIndex = url.indexOf('?');
    if (queryIndex !== -1) {
        const queryString = url.substring(queryIndex + 1);
        const queryParts = queryString.split('&');
        for (const queryPart of queryParts) {
            const parts = queryPart.split('=');
            if (parts.length === 2) {
                const key = decodeURIComponent(parts[0]);
                const value = decodeURIComponent(parts[1]);
                params[key] = value;
            }
        }
    }
    return params;
}

export function getParameterValue(url: string, parameterName: string): string | null {
    const queryIndex = url.indexOf('?');
    if (queryIndex !== -1) {
        const queryString = url.substring(queryIndex + 1);
        const queryParts = queryString.split('&');
        for (const queryPart of queryParts) {
            const parts = queryPart.split('=');
            if (parts.length === 2 && parts[0] === parameterName) {
                return decodeURIComponent(parts[1]);
            }
        }
    }
    return null;
}

