import { APP_INITIALIZER, Provider } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { SwUpdate } from "@angular/service-worker";
import { map, switchMap, tap } from "rxjs/operators";
import { from, Observable, of } from "rxjs";

import { environment } from "../../../environments/environment";

type Meta = { version: string };

/**
 * @param {HttpClient} http to get the latest `meta.json` file.
 * @param {SwUpdate} swUpdate service to hard reload page.
 */
function initializeCacheBuster(
  http: HttpClient,
  swUpdate: SwUpdate
): () => Observable<boolean> {
  const metaFilename = "/meta.json";

  return function (): Observable<boolean> {
    return deleteMetaFromCache().pipe(
      switchMap((): Observable<boolean> => verifyMetaVersion()),
      switchMap((reload: boolean): Observable<boolean> => {
        if (!reload) return of(true);
        if (!window.caches) return updatePWA();
        return reloadCache().pipe(
          switchMap((): Observable<boolean> => updatePWA())
        );
      })
    );
  };

  function deleteMetaFromCache(): Observable<boolean> {
    return from(window.caches.has(metaFilename)).pipe(
      switchMap((isCached: boolean): Observable<boolean> => {
        if (isCached) return from(window.caches.delete(metaFilename));
        return of(false);
      })
    );
  }

  function verifyMetaVersion(): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      "Cache-Control":
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
      Pragma: "no-cache",
      Expires: "0",
    });
    const params: HttpParams = new HttpParams({
      fromObject: { timestamp: Date.now() },
    });
    return http.get<Meta>(metaFilename, { headers, params }).pipe(
      map(({ version }: Meta): boolean => {
        console.log(version, "<!>", environment.shopperPortalVersion);
        return version !== environment.shopperPortalVersion;
      })
    );
  }

  function updatePWA(): Observable<boolean> {
    return from(swUpdate.checkForUpdate()).pipe(
      switchMap((areThereUpdates: boolean): Observable<boolean> => {
        if (areThereUpdates) return from(swUpdate.activateUpdate());
        return of(areThereUpdates);
      }),
      tap((areThereUpdates: boolean): void => {
        areThereUpdates && window.location.reload();
      })
    );
  }

  function reloadCache(): Observable<boolean[]> {
    return from(window.caches.keys()).pipe(
      switchMap((keys: string[]): Observable<boolean[]> => {
        return from(
          Promise.all(
            keys.map((key: string): Promise<boolean> => {
              return window.caches.delete(key);
            })
          )
        );
      })
    );
  }
}

export const CacheBusterInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeCacheBuster,
  deps: [HttpClient, SwUpdate],
  multi: true,
};
