import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLinkActiveOptionsPipe} from './router-link-active-options.pipe';
import { ShowElementIfHasRolePipe } from './show-element-by-role.pipe';


@NgModule({
  declarations: [
    RouterLinkActiveOptionsPipe,
    ShowElementIfHasRolePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RouterLinkActiveOptionsPipe,
    ShowElementIfHasRolePipe
  ]
})
export class MenuModule {
}
