<div class="main-core-loading-screen">
  <div class="loading-logo">
    <img src="assets/images/logo/logo.png" alt="Logo"/>
  </div>
  <div class="loading">
    <div class="effect-1 effects"></div>
    <div class="effect-2 effects"></div>
    <div class="effect-3 effects"></div>
  </div>
</div>
