<!-- app-content-header start -->
<ng-container *ngIf="contentHeader">
  <div class="content-header row">
    <div class="content-header-left {{column}} mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12 d-flex">

          <button class="btn btn-sm btn-icon mr-1 btn-outline-secondary bg-white"
                  rippleEffect
                  *ngIf="contentHeader.backButton && contentHeader.backButton.show"
                  [routerLink]="contentHeader.backButton.link">
            <span feather-icon="arrow-left" size="18"></span>
          </button>

          <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle | translate}}
          </h2>
          <!-- app-breadcrumb component -->
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
        </div>
      </div>
    </div>
    <ng-container *ngIf="contentHeader.actionButton">
      <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
        <div class="form-group breadcrum-right">
          <div ngbDropdown>
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-primary btn-round btn-sm"
              type="button"
              rippleEffect>
              <span [feather-icon]="'grid'"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSettings">
              <a ngbDropdownItem [routerLink]="['/apps/todo']">
                <span [feather-icon]="'check-square'" [class]="'mr-50'"></span> Todo
              </a>
              <a ngbDropdownItem [routerLink]="['/apps/chat']">
                <span [feather-icon]="'message-square'" [class]="'mr-50'"></span> Chat
              </a>
              <a ngbDropdownItem [routerLink]="['/apps/email']">
                <span [feather-icon]="'mail'" [class]="'mr-50'"></span> Email
              </a>
              <a ngbDropdownItem [routerLink]="['/apps/calendar']">
                <span [feather-icon]="'calendar'" [class]="'mr-50'"></span> Calendar
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-content></ng-content>
  </div>
</ng-container>
<!-- app-content-header end -->
