import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';

import {environment} from 'environments/environment';
import {AuthenticationService} from 'app/auth/service';
import {catchError, map, mergeMap} from "rxjs/operators";
import {StorageSessionDataService} from "../service/storage-session-data.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    /**
     *
     * @param {AuthenticationService} _authenticationService
     * @param _storage
     */
    constructor(private readonly _authenticationService: AuthenticationService,
                private readonly _storage: StorageSessionDataService) {
    }

    /**
     * Add auth header with jwt if user is logged in and request is to api url
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this._storage.user;
        const isLoggedIn = currentUser && currentUser.token;
        const isApiUrl = request.url.startsWith(environment.apiUrl) && !request.url.startsWith(`${environment.apiUrl}public`);
        if (isLoggedIn && isApiUrl) {
            if (this._authenticationService.getTokenLifetime() <= 0) {
                return this._authenticationService.fetchNewsTokens()
                    .pipe(
                        map(this._authenticationService.handleResponse),
                        mergeMap(user => {
                            request = request.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${user.token}`
                                }
                            });
                            return next.handle(request);
                        }),
                        catchError(error => {
                            console.error('Failed in fetchNewsTokens', error);
                            this._authenticationService.logout();
                            return EMPTY;
                        })
                    )
            }

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }
        return next.handle(request);
    }

}
