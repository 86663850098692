import { Component } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { languageOptions } from "app/utils";
import { environment } from "environments/environment";
import {LokaliseFileEnum} from "../../enums";
import {LanguagesOptions} from "../../interfaces/translate-language-config";

@Component({
  selector: "app-i18n-select",
  templateUrl: "./i18n-select.component.html",
  styleUrls: ["./i18n-select.component.scss"],
})
export class I18nSelectComponent {
  public languageOptions: LanguagesOptions = languageOptions(environment.enableDefaultLanguage);
  public lokaliseFile: typeof LokaliseFileEnum = LokaliseFileEnum;

  constructor(
    public readonly _translateService: TranslateService,
    private readonly _coreConfigService: CoreConfigService
  ) {}


  public setLanguage(langFile: string): void {

    const langConfig = this.languageOptions[langFile];

    this._coreConfigService.setConfig(
        {
          languageConfig: {
            language: langConfig.language,
            lokaliseFile: langConfig.lokaliseFile,
            javaLocale: langConfig.javaLocale,
            flag: langConfig.flag
          }
        }, {emitEvent: true}
    );
  }

}
