import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {StorageSessionDataService} from "../service/storage-session-data.service";
import {SessionStorageService} from "../service/storage.service";


@Injectable({providedIn: 'root'})
export class NoAuthGuard implements CanActivate {

    constructor(private readonly _router: Router,
                private readonly _storage: StorageSessionDataService,
                private readonly _sessionStorageService: SessionStorageService
    ) {}

    // canActivate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const currentUser = this._storage.user;
        const access_token = this._sessionStorageService.get<string>('access_token');

        if (currentUser && access_token) {
            this._router.navigate(['purchase', 'list']);
            return false;
        } else {
            return true;
        }
    }

}
