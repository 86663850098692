import {Injectable} from "@angular/core";
import { Config } from "@core/interfaces/config";
import { Merchant } from "app/pay/interfaces";
import { User } from "../models";
import { Person } from "../models/person";
import {TranslateLanguageConfig} from "../../../@core/interfaces/translate-language-config";
import {JavaLocaleEnum} from "../../../@core/enums";

@Injectable({providedIn: 'root'})
export class StorageSessionDataService {

    get getLocaleFromConfig() {
        const config = JSON.parse(localStorage.getItem('config'));
        return config?.app?.locale ? config?.app?.locale : JavaLocaleEnum.LATAM
    }

    set user(val: User) {
      localStorage.setItem('currentUser', JSON.stringify(val));
    }

    get user(): User {
      return JSON.parse(localStorage.getItem('currentUser') )
    }

    set person(val: Person) {
      localStorage.setItem('person', JSON.stringify(val));
    }

    get person(): Person {
      return JSON.parse(localStorage.getItem('person') )
    }

    set config(val: Config) {
      localStorage.setItem('config', JSON.stringify(val));
    }

    get config(): Config {
      return JSON.parse(localStorage.getItem('config') )
    }

    set languageConfig(val: TranslateLanguageConfig) {
        localStorage.setItem('languageConfig', JSON.stringify(val));
    }

    get languageConfig(): TranslateLanguageConfig {
        return JSON.parse(localStorage.getItem('languageConfig'));
    }


}
