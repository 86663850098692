import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AccessToken } from "@core/models/checkout-access-token";
import { CheckoutService } from "../service/checkout.service";
import { filter, switchMap, take } from "rxjs/operators";
import {PaymentLinkStatusEnum} from "../../../@core/enums";

@Injectable()
export class AuthCheckoutInterceptor implements HttpInterceptor {
  constructor(private readonly _checkoutService: CheckoutService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    if (this._checkoutService.paymentLinkStatus !== PaymentLinkStatusEnum.ACTIVE ) return next.handle(request);
    if (request.headers.has("Skip-Interceptor")) {
      return next.handle(request);
    }

    return this._checkoutService.authorization$.pipe(
        take(1),
        switchMap((accessToken: AccessToken) => {
          if (accessToken && accessToken?.accessToken) {
            return next.handle(this._addHeaders(request, accessToken));
          } else {
            return next.handle(request);
          }
        })
    );

  }

  private _addHeaders(
    request: HttpRequest<unknown>,
    { tokenType, accessToken }: AccessToken
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `${tokenType} ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
  }
}
