<ng-container *ngIf="!item.hidden">
    <!-- item with router url -->
    <a class="d-flex align-items-center"
       [ngClass]="item.classes"
       *ngIf="item.url && !item.externalUrl"
       [routerLink]="[item.url]"
       [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item with external url -->
    <a class="d-flex align-items-center"
       [ngClass]="item.classes"
       *ngIf="item.url && item.externalUrl"
       [href]="item.url"
       [target]="item.openInNewTab ? '_blank' : '_self'"
    >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <i [class]="'fa ' + item.icon" *ngIf="item.icon" style="height: 15px"></i>
        <span class="menu-title text-truncate">{{ item.translate | translate }}</span>
        <span class="badge ml-auto mr-1"
              *ngIf="item.badge"
              [translate]="item.badge.translate"
              [ngClass]="item.badge.classes"
        >
      {{ item.badge.title }}
    </span>
    </ng-template>
</ng-container>
