import {SHOPPER_PORTAL_VERSION} from "../app/app-version";

export const environment = {
  production: false,
  hmr: false,
  apiZenkiUrl: 'https://uat-api.zenki.fi/',
  apiGatewayUrl: 'https://uat-gateway.zenki.fi/',
  apiUrl: 'https://uat-shopper-gateway.zenki.fi/',
  websocket: "https://uat-shopper-gateway.zenki.fi/pbw/shopper/notifier",
  tiendanube: "https://uat-tiendanube-payment.zenki.fi/",
  enableDefaultLanguage: false,
  keycloak: {
    url: 'https://uat-auth.zenki.fi/auth',
    realm: 'shoppers',
    clientId: 'shopper',
  },
  shopperPortalVersion: SHOPPER_PORTAL_VERSION,
  modalEnvironment: 'uat',
  datadog: {
    applicationId: '1faf58dc-3adc-4a5d-bfe0-5569a4aac1c0',
    clientToken: 'puba86e5da932df525a626f3a5a7e2eb88b',
  },
};
