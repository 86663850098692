import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/constants/window';
import { string2Json, json2String } from '@core/utils/functions/objects';
import { useStorage } from '../helpers/use-storages';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  private readonly _storage: Storage;

  constructor(@Inject(WINDOW) private readonly _window: Window) {
    try {
      this._storage = this._window.sessionStorage;
    } catch (error) {
      this._storage = useStorage();
    }
  }

  public clear(): void {
    this._storage.clear();
  }

  public get<T = unknown>(key: string): T | null {
    const value: string | null = this._storage.getItem(key);
    if (value) return string2Json<T>(value);
    return null;
  }

  public set<T = unknown>(key: string, input: T): void {
    const value: string = json2String(input);
    this._storage.setItem(key, value);
  }

  public delete(key: string): void {
    this._storage.removeItem(key);
  }
}
