export enum LanguagesEnum {
    ES = 'es',
    EN = 'en'
}

export enum LokaliseFileEnum {
    es_AR = 'es_AR',
    en_US = 'en_US',
    es_419 = 'es_419',
    en = 'en'
}

export enum JavaLocaleEnum {
    ARGENTINA = 'es-AR',
    EUA = 'en-US',
    LATAM = 'es-419'
}

export enum FlagEnum {
    ARGENTINA = 'ar',
    US = 'us',
    LATAM = 'latam'
}
